<template>
  <v-list :style="{ border: `1px solid ${color}` }" dense elevation="2">
    <v-list-group no-action :prepend-icon="prependIcon" :color="color">
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title :color="color" :style="{ color: `${color}` }">{{
            title
          }}</v-list-item-title>
        </v-list-item-content>
      </template>

      <slot name="list"></slot>
    </v-list-group>
  </v-list>
</template>

<script>
  export default {
    name: 'AppListCollapsible',
    props: {
      title: {
        type: String,
      },
      prependIcon: {
        type: String,
      },
      color: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
