var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{class:[
    _vm.active ? 'error-message' : '',
    _vm.active && _vm.soloInverted ? 'error-message border-red' : '',
  ],style:({
    width: '100%',
    textAlign: 'center',
  }),attrs:{"value":_vm.value,"hide-details":"","hide-spin-buttons":"","solo-inverted":_vm.soloInverted,"height":_vm.height,"suffix":_vm.suffix,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"input":function($event){return _vm.parseInputString($event)},"blur":function($event){return _vm.$emit('blur')}}})
}
var staticRenderFns = []

export { render, staticRenderFns }