<template>
  <div>
    <AppListCollapsible
      v-if="profilEnergetiquesContractOnError.length"
      class="mt-2"
      title="Ces PDL ont rencontré les erreurs suivantes :"
      prepend-icon="mdi-information-outline"
      color="var(--red-new)"
    >
      <template #list>
        <SfdcContractCreationResultItems
          :profilEnergetiques="profilEnergetiquesContractOnError"
          color="var(--red-new)"
        />
      </template>
    </AppListCollapsible>

    <AppListCollapsible
      v-if="profilEnergetiquesContractOnSucess.length"
      class="mt-2"
      title="Les contrats et périodes de contrat de founiture de ces PDL ont été créés avec succès dans Salesforce :"
      prepend-icon="mdi-information-outline"
      color="var(--green-new)"
    >
      <template #list>
        <SfdcContractCreationResultItems
          :profilEnergetiques="profilEnergetiquesContractOnSucess"
          color="var(--green-new)"
        />
      </template>
    </AppListCollapsible>
  </div>
</template>

<script>
  import { JobStep } from '@Collectif-Energie/rabbit-client';

  import AppListCollapsible from '../app/AppListCollapsible.vue';
  import SfdcContractCreationResultItems from './SfdcContractCreationResultItems.vue';
  export default {
    name: 'SfdcContractCreationResult',
    components: {
      AppListCollapsible,
      SfdcContractCreationResultItems,
    },
    props: {
      profilEnergetiques: {
        type: Array,
      },
    },
    computed: {
      profilEnergetiquesContractOnError() {
        return this.profilEnergetiques.filter(
          (p) => p.contractStatus === JobStep.Error
        );
      },
      profilEnergetiquesContractOnSucess() {
        return this.profilEnergetiques.filter(
          (p) => p.contractStatus === JobStep.Success
        );
      },
    },
  };
</script>

<style lang="scss" scoped></style>
