<template>
  <v-card class="sfdc-contract-creation-list" width="500" height="550">
    <v-subheader>PRM</v-subheader>
    <v-virtual-scroll
      :bench="benched"
      :items="profiles"
      height="500"
      item-height="55"
    >
      <template v-slot:default="{ item }">
        <v-list-item :key="item.prm">
          <v-list-item-content>
            <v-list-item-title>
              <span
                v-if="item.profilEnergetiqueStatus === JobStep.Start"
                :class="[
                  {
                    'contract-success': item.contractStatus === JobStep.Success,
                  },
                  'sfdc-contract-creation-list__item',
                ]"
              >
                {{ item.prm }} - {{ item.endpointCategory }} -
                {{ item.account?.name }}
              </span>
              <span
                v-else
                :class="[
                  {
                    'error-message':
                      item.profilEnergetiqueStatus === JobStep.Error,
                  },
                  'sfdc-contract-creation-list__item',
                ]"
              >
                {{ item.prm }} - PRM absent de salesforce, sera ignoré
              </span>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click="$emit('removeProfilEnergetique', item.prm)">
              <v-icon>highlight_off</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider inset />
      </template>
    </v-virtual-scroll>
  </v-card>
</template>

<script>
  import { JobStep } from '@Collectif-Energie/rabbit-client';

  export default {
    name: 'SfdcContractCreationList',
    props: {
      profilEnergetiques: {
        type: Array,
        Required: true,
      },
    },
    data() {
      return {
        JobStep,
        benched: 0,
      };
    },
    computed: {
      profiles() {
        return [...this.profilEnergetiques].sort(
          (profileEnergetiqueA, profileEnergetiqueB) => {
            const stausA = profileEnergetiqueA.profilEnergetiqueStatus;
            const stausB = profileEnergetiqueB.profilEnergetiqueStatus;

            const orderA = stausA === JobStep.Start ? 1 : -1;
            const orderB = stausB === JobStep.Start ? 1 : -1;

            return orderB - orderA;
          }
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sfdc-contract-creation-list {
    &__item {
      font-size: 0.8em;
    }
  }

  .contract-success {
    color: var(--green-new);
  }
</style>
