<template>
  <v-text-field
    :value="value"
    hide-details
    hide-spin-buttons
    :solo-inverted="soloInverted"
    :height="height"
    :suffix="suffix"
    :placeholder="placeholder"
    :disabled="disabled"
    @input="parseInputString($event)"
    @blur="$emit('blur')"
    :class="[
      active ? 'error-message' : '',
      active && soloInverted ? 'error-message border-red' : '',
    ]"
    :style="{
      width: '100%',
      textAlign: 'center',
    }"
  />
</template>

<script>
  export default {
    name: 'AppInputNumber',
    props: {
      value: {
        type: [Number, String],
      },
      soloInverted: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      height: {
        type: Number,
      },
      suffix: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      min: {
        type: Number,
      },
      max: {
        type: Number,
      },
    },
    data() {
      return {
        active: false,
      };
    },
    methods: {
      parseInputString(event) {
        let num = event.replace(/ /g, '').replace(/,/g, '.');
        if (/^[+-]?(\d+\.?\d*|\.\d+)$/.test(num)) num = Number(num);
        if (num < this.min) num = this.min;
        if (num > this.max) num = this.max;

        if (isNaN(num)) {
          this.active = true;
          this.$emit('input', null);
        } else {
          this.active = false;
          this.$emit('input', num);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';

  .border-red {
    border: solid 2px var(--red-new);
  }
</style>
