var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"sfdc-contract-creation-list",attrs:{"width":"500","height":"550"}},[_c('v-subheader',[_vm._v("PRM")]),_c('v-virtual-scroll',{attrs:{"bench":_vm.benched,"items":_vm.profiles,"height":"500","item-height":"55"},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-list-item',{key:item.prm},[_c('v-list-item-content',[_c('v-list-item-title',[(item.profilEnergetiqueStatus === _vm.JobStep.Start)?_c('span',{class:[
                {
                  'contract-success': item.contractStatus === _vm.JobStep.Success,
                },
                'sfdc-contract-creation-list__item',
              ]},[_vm._v(" "+_vm._s(item.prm)+" - "+_vm._s(item.endpointCategory)+" - "+_vm._s(item.account?.name)+" ")]):_c('span',{class:[
                {
                  'error-message':
                    item.profilEnergetiqueStatus === _vm.JobStep.Error,
                },
                'sfdc-contract-creation-list__item',
              ]},[_vm._v(" "+_vm._s(item.prm)+" - PRM absent de salesforce, sera ignoré ")])])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('removeProfilEnergetique', item.prm)}}},[_c('v-icon',[_vm._v("highlight_off")])],1)],1)],1),_c('v-divider',{attrs:{"inset":""}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }