<template>
  <div class="d-flex pt-4 pb-4">
    <table>
      <thead>
        <tr>
          <th class="no-color"></th>
          <th
            v-for="temporalClass in temporalClasses"
            :key="'temporal-class-head' + temporalClass.name"
          >
            {{ temporalClass.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            class="no-color"
            :style="{ whiteSpace: 'nowrap', minWidth: '10rem' }"
          >
            Prix electron €/MWh
          </td>
          <td
            v-for="temporalClass in temporalClasses"
            :key="temporalClass.name"
          >
            <AppInputNumber
              v-model.number="temporalClass.powerPrice"
              :soloInverted="true"
              @input="
                $emit('updatePrices', $event, temporalClass.name, 'powerPrice')
              "
              :style="{ fontSize: '0.85em' }"
            />
          </td>
        </tr>
        <tr>
          <td class="no-color" :style="{ whiteSpace: 'nowrap' }">
            Prix CAPA €/MWh
          </td>
          <td
            v-for="temporalClass in temporalClasses"
            :key="temporalClass.name"
          >
            <AppInputNumber
              v-model.number="temporalClass.capacityPrice"
              :soloInverted="true"
              @input="
                $emit(
                  'updatePrices',
                  $event,
                  temporalClass.name,
                  'capacityPrice'
                )
              "
              :style="{ fontSize: '0.85em' }"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import AppInputNumber from '../app/AppInputNumber.vue';

  export default {
    name: 'SfdcContractCreationTable',
    components: {
      AppInputNumber,
    },
    props: {
      temporalClasses: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {};
    },
    methods: {},
  };
</script>
<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';
  @import 'src/client/style/table.scss';
  table {
    width: 50rem;
  }
  .no-color {
    background-color: transparent !important;
  }
  th {
    padding: 0.8rem !important;
  }
</style>
