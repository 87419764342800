<template>
  <div>
    <v-list-item
      v-for="(profilEnergetique, i) in profilEnergetiques"
      :key="profilEnergetique + i"
    >
      <v-list-item-content class="sfdc-contract-creation-result-items__content">
        <v-list-item-title :style="{ color: `${color}` }">
          <v-icon :color="color">mdi-circle-small</v-icon>
          {{ profilEnergetique.prm }}
          <span v-show="profilEnergetique.contractErrorMessage">
            - {{ profilEnergetique.contractErrorMessage }}
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
  export default {
    name: 'SfdcContractCreationResultItems',
    props: {
      profilEnergetiques: {
        type: Array,
      },
      color: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sfdc-contract-creation-result-items {
    &__content {
      margin-left: 2rem;
    }
  }
</style>
