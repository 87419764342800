<template>
  <div :style="{ backgroundColor: 'var(--secondary-grey-new) !important' }">
    <v-card v-if="!generalError" class="pa-6" outlined>
      <v-row align="center" class="d-flex">
        <v-col lg="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                block
                color="primary"
                @click.prevent="findProfilEnergetiques"
                data-test="submitDt"
                v-on="on"
                :loading="searchProfilEnergetiqueLoading"
              >
                Lancer une consultation
              </v-btn>
            </template>
            <div :style="{ textAlign: 'center' }">
              Rechercher les PDL dans Salesforce.
            </div>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>

    <SfdcContractCreationResult :profilEnergetiques="profilEnergetiques" />

    <v-card
      v-if="!generalError"
      class="d-flex pa-8 mt-2"
      :style="{ width: '100%', backgroundColor: 'var(--white)', gap: '1rem' }"
    >
      <SfdcContractCreationList
        :profilEnergetiques="profilEnergetiques"
        @removeProfilEnergetique="removeProfilEnergetique"
      />

      <div :style="{ width: '65%' }">
        <div class="d-flex pl-4">
          <div
            :style="{
              alignSelf: 'center',
              marginBottom: '1rem',
              whiteSpace: 'nowrap',
              minWidth: '10rem',
            }"
          >
            Fournisseur *
          </div>
          <div class="d-flex" :style="{ width: '60rem' }">
            <div
              :style="{
                width: '35%',
              }"
            >
              <v-form @submit.prevent="resetForm(), findSfdcSupplierAccounts()">
                <v-text-field
                  class="pt-0"
                  v-model="inputSupplier"
                  placeholder="rechercher un fournisseur"
                  datalistOpen="datalist"
                  @change="$v.inputSupplier.$touch()"
                  @blur="$v.inputSupplier.$touch()"
                  :error-messages="[...inputSupplierErrors, ...errors]"
                ></v-text-field>
              </v-form>
              <div :style="{ position: 'absolute', width: '20rem' }">
                <ul class="datalist" v-show="datalistOpen">
                  <li
                    class="datalist__li"
                    v-for="(supplier, i) in suppliers"
                    :key="'supplier' + i"
                    @click="selectAndCloseDatalist(i)"
                  >
                    <p class="datalist__li__value">
                      {{ supplier.name }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <button
              type="button"
              @click.prevent="resetForm(), findSfdcSupplierAccounts()"
            >
              <v-icon v-if="!searchSupplierLoading" id="search" class="ml-2"
                >mdi-magnify</v-icon
              >
              <v-progress-circular
                v-if="searchSupplierLoading"
                indeterminate
                color="var(--primary)"
                size="25"
              ></v-progress-circular>
            </button>
          </div>
        </div>
        <p v-show="errorSupplierRequired" class="error-message ml-8">
          Vous devez avoir un founisseur de sélectionné
        </p>

        <div class="d-flex pl-4">
          <div
            :style="{
              whiteSpace: 'nowrap',
              minWidth: '10rem',
            }"
          >
            Type de contrat *
          </div>
          <div class="d-flex flex-wrap" :style="{ width: '40rem' }">
            <div
              v-for="(contractOption, i) in contractOptions"
              :key="'accounts' + i"
              :style="{ whiteSpace: 'nowrap', width: '170px' }"
            >
              <input
                type="radio"
                :id="contractOption"
                :value="contractOption"
                name="contractOption"
                v-model="contractSelected"
                @change="resetForm()"
              />
              {{ contractOption.label }}
            </div>
          </div>
        </div>

        <div class="d-flex pl-4 mt-4">
          <div class="mb-4" :style="{ minWidth: '10rem' }">
            Dates application *
          </div>
          <div>
            <AppDateSelectionShortcut
              :nbTotalButton="4"
              :startYearsSubstract="2"
              @selectShortcutYear="selectYears"
            />
            <div class="d-flex mr-12 mt-4">
              <div class="mr-10">
                <v-menu
                  v-model="menuDateDebut"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDateInput"
                      :height="25"
                      :style="{ fontSize: '0.8em' }"
                      :error-messages="startDateErrors"
                      :error="!startDateBeforeEndDate"
                      label="Date de début (incluse)"
                      prepend-icon="mdi-calendar"
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDateInput"
                    no-title
                    :min="minDate"
                    :max="maxDate"
                    locale="fr-FR"
                    @input="
                      () => {
                        $v.startDateInput.$touch();
                        menuDateDebut = false;
                      }
                    "
                    @blur="$v.startDateInput.$touch()"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div>
                <v-menu
                  v-model="menuDateFin"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDateInput"
                      :height="25"
                      :style="{ fontSize: '0.8em' }"
                      :error-messages="endDateErrors"
                      :error="!startDateBeforeEndDate"
                      label="Date de fin (incluse)"
                      prepend-icon="mdi-calendar"
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDateInput"
                    no-title
                    :min="minDate"
                    :max="maxDate"
                    locale="fr-FR"
                    @input="
                      () => {
                        $v.endDateInput.$touch();
                        menuDateFin = false;
                      }
                    "
                    @blur="$v.endDateInput.$touch()"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex pl-4 mt-2">
          <div class="align-self-end" :style="{ minWidth: '10rem' }">
            Abonnement
          </div>
          <div class="mr-12">
            <AppInputNumber
              v-model.number="yearlyRate"
              :height="25"
              :suffix="'€/an'"
              :placeholder="'annuel €/an'"
              :style="{ fontSize: '0.8em' }"
              @input="yearlyRate = $event"
            />
          </div>
          <div>
            <AppInputNumber
              v-model.number="consumptionBasedRate"
              :height="25"
              :suffix="'€/MWh'"
              :placeholder="'variable €/MWh'"
              :style="{ fontSize: '0.8em' }"
              @input="consumptionBasedRate = $event"
            />
          </div>
        </div>

        <div class="d-flex pl-4 mt-2">
          <div class="align-self-end" :style="{ minWidth: '10rem' }">CEE</div>
          <div>
            <AppInputNumber
              v-model.number="ceePrice"
              :height="25"
              :suffix="'€/MWh'"
              :placeholder="'€/MWh'"
              :style="{ fontSize: '0.8em' }"
              @input="ceePrice = $event"
            />
          </div>
        </div>

        <SfdcContractCreationTable
          :temporalClasses="temporalClasses"
          @updatePrices="updatePrices"
        />

        <p v-show="!startDateBeforeEndDate" class="error-message pa-0">
          La date de fin doit être postérieure à la date de début.
        </p>
        <p v-show="errorValidationRequired" class="error-message pa-0">
          Champs obligatoires manquants
        </p>
        <div class="d-flex">
          <v-btn
            :disabled="createContractLoading"
            color="secondary"
            text
            width="165"
            class="new-button__secondary"
            @click.prevent="resetForm(), clearAll()"
          >
            Annuler
          </v-btn>
          <v-btn
            :disabled="createContractLoading || profilEnergetiques.length === 0"
            color="primary"
            class="new-button"
            :loading="createContractLoading"
            @click.prevent="createSfdcContracts()"
          >
            CREER CES CONTRATS POUR LES PDL SELECTIONNÉS
          </v-btn>
        </div>
      </div>
    </v-card>

    <div :style="{ backgroundColor: 'var(--white) !important' }">
      <v-alert v-if="generalError" align="center" dense outlined type="error">
        <div class="title">Erreur général</div>
        <pre :style="{ overflow: 'hidden' }">{{
          JSON.stringify(generalError, null, '\t')
        }}</pre>
      </v-alert>
    </div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import socket from '../../socket';
  import { mapState } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import {
    accountsEmpty,
    currentSearchMin,
  } from '../../validators/generalValidators';
  import {
    minPrmLengthArray,
    maxPrmLengthArray,
    formatPrmArray,
    isStartDateBeforeEndDate,
  } from '../../validators/enedisValidators';
  import {
    classicTemporalClass,
    priceCategory,
  } from '../../constants/posteHorosaisonnierOrder.constants';
  import { eJobStatus } from '../../services/TransportBudget.mapper';
  import { JobStep } from '@Collectif-Energie/rabbit-client';

  import AppInputNumber from '../app/AppInputNumber.vue';
  import AppDateSelectionShortcut from '../app/AppDateSelectionShortcut.vue';

  import SfdcContractCreationTable from './SfdcContractCreationTable.vue';
  import SfdcContractCreationList from './SfdcContractCreationList.vue';
  import SfdcContractCreationResult from './SfdcContractCreationResult.vue';
  import salesforceGatewayClient from '../../services/salesforce-gateway/salesforce-gateway.client';
  import {
    MatomoService,
    MATOMO_EVENTS,
  } from './../../services/MatomoApi.service';

  export default {
    name: 'SfdcContractCreationTabs',
    components: {
      SfdcContractCreationList,
      SfdcContractCreationTable,
      SfdcContractCreationResult,
      AppInputNumber,
      AppDateSelectionShortcut,
    },
    props: {
      prms: {
        type: Array,
      },
      validation: {
        type: Function,
      },
    },
    data() {
      return {
        profilEnergetiques: [],
        suppliers: [{ accountId: '0012p00003A9pcBAAR', name: 'EDF' }],
        supplierId: '0012p00003A9pcBAAR',
        inputSupplier: 'EDF',
        startDateInput: moment().format('YYYY-MM-DD'),
        endDateInput: '',
        contractOptions: priceCategory,
        contractSelected: priceCategory[0],
        yearlyRate: null,
        consumptionBasedRate: null,
        ceePrice: null,
        maxDate: moment()
          .add(10, 'years')
          .tz('Europe/Paris')
          .format('YYYY-MM-DD'),
        minDate: moment()
          .subtract(12, 'years')
          .tz('Europe/Paris')
          .format('YYYY-MM-DD'),
        menuDateDebut: false,
        menuDateFin: false,
        datalistOpen: false,
        errors: [],
        generalError: null,
        inputSupplierErrors: [],
        inputProfilEnergetiqueErrors: [],
        errorSupplierRequired: false,
        errorValidationRequired: false,
        searchSupplierLoading: false,
        searchProfilEnergetiqueLoading: false,
        createContractLoading: false,
      };
    },
    computed: {
      ...mapState('auth', ['user']),
      startDateErrors() {
        const errors = [];
        if (!this.$v.startDateInput.$dirty) return errors;
        !this.$v.startDateInput.required &&
          errors.push('Veuillez renseigner une date de début');
        return errors;
      },
      endDateErrors() {
        const errors = [];
        if (!this.$v.endDateInput.$dirty) return errors;
        !this.$v.endDateInput.required &&
          errors.push('Veuillez renseigner une date de fin');
        return errors;
      },
      inputSupplierError() {
        const errors = [];
        if (!this.$v.inputSupplier.$dirty) return errors;
        !this.$v.inputSupplier.required &&
          errors.push('Veuillez renseigner un nom de compte');
        return errors;
      },
      temporalClasses() {
        const temporalClasses = [];
        for (const temporalClass of classicTemporalClass) {
          temporalClasses.push({
            name: temporalClass,
            powerPrice: null,
            capacityPrice: null,
          });
        }
        return temporalClasses;
      },
      startDateBeforeEndDate() {
        if (this.startDateInput && this.endDateInput) {
          const startDate = moment(this.startDateInput);
          const endDate = moment(this.endDateInput).add(1, 'day');

          if (!startDate.isBefore(endDate)) {
            return false;
          }
        }

        return true;
      },
    },
    created() {
      socket.on('salesforce:accounts:fetch', (response) => {
        if (this.suppliers.length || !this.inputSupplier) return;

        if (response.status === eJobStatus.Completed) {
          this.searchSupplierLoading = false;
          if (!response.accounts)
            this.generalError =
              'Une anomalie est survenue durant la récupération des comptes Salesforce';
          else if (!response.accounts.length)
            this.inputSupplierErrors.push("Aucun compte n'a été trouvé");
          else this.datalistOpen = true;

          this.suppliers = response.accounts;
        }

        if (response.status === eJobStatus.Failed)
          this.generalError = response.message;
      });
      socket.on('salesforce:contract:creation', (response) => {
        if (response.status === JobStep.Finish) {
          if (!response.contractReport?.jobSuccess)
            response.profilEnergetiqueIds.forEach((profilEnergetiqueId) => {
              this.updateContractCreationStatus(
                profilEnergetiqueId,
                JobStep.Error,
                response.contractReport.errorMessage
              );
            });

          response.contractReport.periodsReport.forEach((periodReport) => {
            this.updateContractCreationStatus(
              periodReport.profilEnergetiqueId,
              periodReport.jobSuccess ? JobStep.Success : JobStep.Error,
              periodReport.errorMessage
            );
          });
        }
      });
    },
    mounted() {
      MatomoService.trackPageView('Saisie de contrat');
    },
    watch: {
      profilEnergetiques() {
        const activeProfilesLength = this.profilEnergetiques.filter(
          (e) => e.profilEnergetiqueStatus === JobStep.Start
        ).length;

        const finishedProfilesLength = this.profilEnergetiques.filter(
          (e) =>
            e.contractStatus === JobStep.Success ||
            e.contractStatus === JobStep.Error
        ).length;

        if (finishedProfilesLength === activeProfilesLength)
          this.createContractLoading = false;
      },
      startDateInput() {
        this.resetForm();
      },
      endDateInput() {
        this.resetForm();
      },
      inputSupplier() {
        this.resetForm();
      },
    },
    validations: {
      prms: {
        required,
        minPrmLengthArray,
        maxPrmLengthArray,
        formatPrmArray,
      },
      startDateInput: { required, isStartDateBeforeEndDate },
      endDateInput: { required },
      inputSupplier: { required, currentSearchMin },
      suppliers: { required, accountsEmpty },
      profilEnergetiques: { required, accountsEmpty },
    },
    methods: {
      selectYears(year) {
        this.resetForm();
        this.startDateInput = year + '-01-01';
        this.endDateInput = year + '-12-31';
      },
      checkForm() {
        this.$v.$touch();
        return !this.$v.$invalid;
      },
      checkData(response) {
        if (!response.data) throw new Error('Aucune données disponible');
      },
      resetForm() {
        this.errors = [];
        this.inputSupplierErrors = [];
        this.inputProfilEnergetiqueErrors = [];
        this.datalistOpen = false;
        this.errorSupplierRequired = false;
        this.errorValidationRequired = false;
      },
      clearAll() {
        this.suppliers = [];
        this.supplierId = null;
        this.profilEnergetiques = [];
        this.inputSupplier = '';
        this.startDateInput = '';
        this.endDateInput = '';
        this.contractOptions = priceCategory;
        this.contractSelected = priceCategory[0];
        this.yearlyRate = null;
        this.consumptionBasedRate = null;
        this.ceePrice = null;
      },
      updatePrices(event, temporalClass, source) {
        this.temporalClasses.forEach((e) => {
          if (e.name === temporalClass) {
            source === 'powerPrice'
              ? (e.powerPrice = event)
              : (e.capacityPrice = event);
          }
        });
      },
      selectAndCloseDatalist(i) {
        this.inputSupplier = this.suppliers[i].name;
        this.supplierId = this.suppliers[i].accountId;
        this.datalistOpen = false;
      },
      removeProfilEnergetique(prm) {
        const i = this.profilEnergetiques.findIndex((p) => p.prm === prm);

        return this.profilEnergetiques.splice(i, 1);
      },
      async findProfilEnergetiques() {
        if (!this.prms.length) return this.$v.prms.$dirty;

        MatomoService.trackEvent(
          MATOMO_EVENTS.consultContractProfiles,
          this.prms.length
        );

        try {
          this.profilEnergetiques = [];
          this.searchProfilEnergetiqueLoading = true;

          const prms = [...new Set(this.prms)];

          const data = await salesforceGatewayClient.fetchProfilEnergetiques(
            prms
          );

          this.profilEnergetiques = prms.map((prm) => {
            const profile = data.find((p) => p.prm === prm);

            return profile
              ? {
                  ...profile,
                  profilEnergetiqueStatus: JobStep.Start,
                }
              : {
                  prm,
                  profilEnergetiqueStatus: JobStep.Error,
                };
          });
        } catch (err) {
          if (err.response?.data?.message)
            this.generalError = err.response.data.message;
          else this.generalError = err.message;
        } finally {
          this.searchProfilEnergetiqueLoading = false;
        }
      },
      async findSfdcSupplierAccounts() {
        this.inputSupplierErrors = [];
        if (!this.inputSupplier.length) {
          this.$v.inputSupplier.dirty;
          return this.inputSupplierErrors.push('Veuillez renseigner un compte');
        }

        try {
          this.suppliers = [];
          this.searchSupplierLoading = true;
          const response = await this.$http.get('/salesforce/suppliers', {
            params: {
              currentSearch: this.inputSupplier,
            },
            withCredentials: true,
          });
          this.checkData(response);
        } catch (err) {
          if (err.response?.data?.message)
            this.generalError = err.response.data.message;
          else this.generalError = err.message;
        }
      },
      async createSfdcContracts() {
        if (!this.checkForm() || this.$v.$invalid)
          this.errorValidationRequired = true;
        if (!this.suppliers.length) return (this.errorSupplierRequired = true);
        if (!this.inputSupplier) this.inputSupplier = this.suppliers[0].name;

        if (this.checkForm() && !this.$v.$invalid) {
          MatomoService.trackEvent(
            MATOMO_EVENTS.createContracts,
            this.profilEnergetiques.length
          );

          try {
            this.createContractLoading = true;

            const payload = this.createPayload();
            await this.$http.post(
              '/salesforce/contract-prices',
              {
                ...payload,
              },
              { withCredentials: true }
            );
          } catch (err) {
            if (err?.response?.data?.message)
              this.generalError = err.response.data.message;
            else this.generalError = err.message;
          }
        }
      },
      createPayload() {
        const prices = {};
        this.temporalClasses.forEach((tc) => {
          const temporalClass = tc.name;
          prices[temporalClass] = prices[temporalClass] || {
            powerPrice: null,
            capacityPrice: null,
          };

          prices[temporalClass].powerPrice = Number(tc.powerPrice) || null;
          prices[temporalClass].capacityPrice =
            Number(tc.capacityPrice) || null;
        });

        const payload = {
          supplierId: this.supplierId,
          prices: {
            powerPrices: {
              BASE: prices.BASE.powerPrice,
              HP: prices.HP.powerPrice,
              HC: prices.HC.powerPrice,
              PTE: prices.PTE.powerPrice,
              HPH: prices.HPH.powerPrice,
              HCH: prices.HCH.powerPrice,
              HPE: prices.HPE.powerPrice,
              HCE: prices.HCE.powerPrice,
            },
            capacityPrices: {
              BASE: prices.BASE.capacityPrice,
              HP: prices.HP.capacityPrice,
              HC: prices.HC.capacityPrice,
              PTE: prices.PTE.capacityPrice,
              HPH: prices.HPH.capacityPrice,
              HCH: prices.HCH.capacityPrice,
              HPE: prices.HPE.capacityPrice,
              HCE: prices.HCE.capacityPrice,
            },
            ceePrice: this.ceePrice,
            yearlyRate: this.yearlyRate,
            consumptionBasedRate: this.consumptionBasedRate,
            priceCategory: this.contractSelected.code,
            applicationStartDate: this.startDateInput,
            applicationEndDate: this.endDateInput,
          },
          profilEnergetiques: this.profilEnergetiques
            .filter(
              (profile) => profile.profilEnergetiqueStatus === JobStep.Start
            )
            .map((profile) => {
              return {
                accountId: profile.account.accountId,
                profilEnergetiqueId: profile.salesforceId,
              };
            }),
        };

        return payload;
      },
      updateContractCreationStatus(profilEnergetiqueId, status, error) {
        const index = this.profilEnergetiques.findIndex(
          (p) => p.salesforceId === profilEnergetiqueId
        );

        if (index === -1) return;

        const profilEnergetique = this.profilEnergetiques[index];
        profilEnergetique.contractStatus = status;
        profilEnergetique.contractErrorMessage = error;

        return this.profilEnergetiques.splice(index, 1, profilEnergetique);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';

  .datalist {
    bottom: 1rem;
    width: 100%;
    max-height: 350px;
    position: relative;
    z-index: 1000;
    list-style: none;
    margin: 0;
    padding: 5px 0;
    background-color: white;
    border: 1px #ccc solid;
    box-shadow: 0px 3px 4px rgba(172, 171, 171, 0.705);
    overflow: auto;

    &__li {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      font-size: 1em;

      &:hover {
        background-color: #ccc;
      }

      &__value {
        margin: 0;
        padding: 1rem;
      }
    }
  }

  .new-button {
    padding: 1rem !important;
    border-radius: 0;

    &__secondary {
      padding: 1rem !important;
    }
  }

  .error-message {
    font-size: 0.8em;
  }

  .year-btn.v-btn--outlined {
    border: thin solid var(--secondary);
  }
</style>
